<template>
  <div class="notfound">
    <div class="number">404</div>
    <div class="text">你要的东西去娜美星了！</div>
    <router-link
      class="btn"
      :to="{ path: '/' }"
    >返回首页</router-link>
  </div>
</template>
<script lang="ts">
export default defineComponent({
  name: '404',
});
</script>
<style lang="less" scoped>
@import "../../styles/config.less";
.notfound{
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: @c_bg_line;
  .number{
    font-size: 1rem;
    font-weight: bold;
    color: @c_active;
  }
  .text{
    padding: 0.1rem 0;
    font-size: 0.3rem;
    color: @c_text;
  }
  .btn{
    padding: 0.1rem 0;
    font-size: 0.2rem;
    color: @c_title;
  }
}
</style>
